import type { ReactElement, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/nextjs";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import * as yup from "yup";
import useUserStore from "stores/use-user-store";
import TextLink from "components/common/link/text-link";
import PrivcoLogoColor from "components/icons/privco-logo-color";
import useClearProfileData from "hooks/use-clear-profile-data";
import { getFirst } from "utils/array";
import { post } from "utils/fetcher";
import getPayloadCookie from "utils/get-payload-cookie";
import type {
  InvalidLoginMessage,
  InvalidLoginResponse,
  LoginRequest,
  ValidLoginResponse,
} from "types/api/auth/login";
import type { MasterAccessResponse } from "types/api/auth/master-access";
import DirectAccessButton from "./direct-access-button";

const TermsAndPrivacy = () => (
  <Box
    bg="yellow.100"
    mt={6}
    px={4}
    py={3}
    rounded="md"
    shadow="xs"
    borderLeftColor="orange.400"
    borderLeftWidth="0.25rem"
    borderRightColor="transparent"
    borderRightWidth="0.25rem"
  >
    <Text fontSize="sm" textAlign="center">
      By continuing, you agree to the latest{" "}
      <TextLink isExternal href="https://privco.com/terms-of-use">
        Terms of Use
      </TextLink>{" "}
      and{" "}
      <TextLink isExternal href="https://privco.com/privacy-policy">
        Privacy Policy
      </TextLink>
      .
    </Text>
  </Box>
);

interface SignInFormWrapperProps {
  isAcademic?: boolean;
  isFirst?: boolean;
  children: ReactNode;
}

const SignInFormWrapper = ({
  isAcademic = false,
  isFirst = true,
  children,
}: SignInFormWrapperProps) => (
  <Box
    mb={6}
    p={[4, 6, 8]}
    rounded="lg"
    boxShadow="xl"
    bg="white"
    w="100%"
    maxW="24rem"
  >
    {isFirst && (
      <Center mb={[6, 8]}>
        <Box
          w={[10, 12]}
          as="a"
          href="https://www.privco.com"
          aria-label="PrivCo Home"
        >
          <PrivcoLogoColor w="100%" h="auto" />
        </Box>
      </Center>
    )}

    <Heading
      as={isFirst ? "h1" : "h2"}
      fontSize={["xl", "2xl"]}
      mb={[6, 8]}
      fontWeight="medium"
      textAlign="center"
    >
      Sign in to PrivCo {isAcademic && "Academic"}
    </Heading>

    {children}

    <TermsAndPrivacy />
  </Box>
);

const generalMessages: Partial<Record<InvalidLoginMessage, string>> = {
  NOT_ACTIVE:
    "Your account is inactive, please contact support@privco.com for details.",
  TURNED_OFF:
    "Access from your IP address has been blocked. Please contact support@privco.com for details.",
  GRADUATED:
    "This account has expired. Please contact your librarian for further details.",
  PENDING:
    "This account has not been confirmed yet. Please check your email to confirm your account. If this error persists, contact support@privco.com",
  MASTER_INACTIVE:
    "This account's subscription is inactive. Please contact support@privco.com.",
  BASIC_GROUP_404:
    "This account's subscription is inactive. Please contact support@privco.com.",
  INACTIVE_SUB:
    "The account's subscription is inactive. Please contact support@privco.com.",
  STRIPE_CUSTOMER_404:
    "The account's subscription is inactive. Please contact support@privco.com.",
  MASTER_SUB_404:
    "This account does not appear to be attached to an institution. Please contact support@privco.com.",
  SUB_TYPE_PLACEHOLDER_404:
    "This account has a legacy subscription. Please contact support@privco.com.",
  MASTER_END_CONFLICT:
    "This account has a conflict. Please contact support@privco.com.",
  INVALID_LOGIN:
    "There was a problem signing you in. Please contact support@privco.com.",
};

const CreateAccountLink = ({
  isAcademic,
}: {
  isAcademic: boolean;
}): ReactElement => (
  <Text textAlign="center" mt={4}>
    New to PrivCo?{" "}
    <TextLink href={isAcademic ? "/academic-signup" : "/signup"}>
      Create an Account
    </TextLink>
  </Text>
);

interface DirectAccessSettings extends MasterAccessResponse {
  isLoading: boolean;
}

interface FormValues {
  email: string;
  password: string;
  general: "";
}

const defaultValues: FormValues = {
  email: "",
  password: "",
  general: "",
};

const SignInForm = (): ReactElement => {
  const { updateUser } = useUserStore();
  const router = useRouter();

  const clearProfileData = useClearProfileData();

  const {
    query: { redirect },
  } = router;

  const [directAccessSettings, setDirectAccessSettings] =
    useState<DirectAccessSettings>({
      allowDirectAccess: false,
      allowSubsidiaryUsers: false,
      isLoading: true,
    });

  const [shouldShowSupportMessage, setShowSupportMessage] =
    useState<boolean>(false);
  const [shouldShowAcademicSupportMessage, setShowAcademicSupportMessage] =
    useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const {
    setFocus,
    handleSubmit,
    register,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (directAccessSettings.allowSubsidiaryUsers) {
      setFocus("email");
    }
  }, [directAccessSettings.allowSubsidiaryUsers, setFocus]);

  const submit = async (rawFormValues: FormValues) => {
    const { general, ...formValues } = rawFormValues;

    try {
      const res = await post<LoginRequest, ValidLoginResponse>(
        "/system/auth/login",
        formValues
      );

      if (
        res.destination &&
        res.destination !== "/" &&
        res.destination.startsWith("/")
      ) {
        await router.replace(`/signin?redirect=${res.destination}`, undefined);
      }

      updateUser({
        isLoggedIn: true,
        loading: false,
        details: {
          id: res.id,
          username: res.username,
          intercom: res.intercom,
          directAccess: getPayloadCookie().data.directAccess,
        },
        access: {
          group: res.userAccessGroup,
          sessionObjective: res.userHasSessionObjective,
          permissions: res.userPermissions,
        },
      });

      await clearProfileData();

      const redirectStr = getFirst(redirect);

      if (!redirectStr) {
        router.push("/");
      } else {
        router.push(
          redirectStr.startsWith("/") ? redirectStr : `/${redirectStr}`
        );
      }
    } catch (err) {
      setShowSupportMessage(true);

      const { message } = err.info as InvalidLoginResponse;

      if (generalMessages[message]) {
        setError("general", {
          type: "manual",
          message: generalMessages[message],
        });
      } else if (message === "INVALID_LOGIN") {
        setError(
          "email",
          {
            type: "manual",
            message: "One or more fields was not entered.",
          },
          { shouldFocus: true }
        );
      } else {
        captureException(err.info);
        setError("general", {
          type: "manual",
          message:
            "There was an issue processing your request, please try again.",
        });
      }
    }
  };

  const { data: accessChecks } = useSWR<MasterAccessResponse>(
    "/system/auth/master-access"
  );

  useEffect(() => {
    if (accessChecks && directAccessSettings.isLoading) {
      setDirectAccessSettings({
        allowDirectAccess: accessChecks.allowDirectAccess,
        allowSubsidiaryUsers: accessChecks.allowSubsidiaryUsers,
        isLoading: false,
      });
    }
  }, [accessChecks, directAccessSettings.isLoading]);

  if (directAccessSettings.isLoading) {
    return (
      <SignInFormWrapper>
        <Center minH="250px">
          <Spinner color="blue.500" />
        </Center>
      </SignInFormWrapper>
    );
  }

  const shouldShowAcademicForm = directAccessSettings.allowDirectAccess;
  const shouldShowCredentialsForm =
    !shouldShowAcademicForm || directAccessSettings.allowSubsidiaryUsers;

  return (
    <>
      {shouldShowAcademicForm && (
        <SignInFormWrapper isAcademic>
          <DirectAccessButton
            setShowSupportMessage={setShowAcademicSupportMessage}
          />

          {shouldShowAcademicSupportMessage && (
            <Text fontSize="sm" mt={4}>
              If you are having trouble, please email{" "}
              <TextLink isExternal href="mailto:support@privco.com">
                support@privco.com
              </TextLink>{" "}
              and we will respond as quickly as possible.
            </Text>
          )}
        </SignInFormWrapper>
      )}

      {shouldShowCredentialsForm && (
        <SignInFormWrapper
          isFirst={!shouldShowAcademicForm}
          isAcademic={directAccessSettings.allowSubsidiaryUsers}
        >
          <Box as="form" w="100%" onSubmit={handleSubmit(submit)} mb={6}>
            <FormControl id="username" isInvalid={!!errors?.email}>
              <FormLabel>Email</FormLabel>

              <Input
                {...register("email")}
                type="text"
                placeholder="Enter your email"
              />

              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <Box position="relative">
              <FormControl mt="4" id="password" isInvalid={!!errors?.password}>
                <FormLabel>Password</FormLabel>

                <Input
                  {...register("password")}
                  type="password"
                  placeholder="Enter your password"
                />

                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>

              <Button
                data-cy="submit-signin-form"
                type="submit"
                colorScheme="blue"
                w="full"
                mt={4}
                isLoading={isSubmitting}
              >
                Sign In
              </Button>

              <FormControl isInvalid={!!errors.general}>
                <FormErrorMessage>
                  {errors.general && errors.general.message}
                </FormErrorMessage>
              </FormControl>

              <TextLink
                href="/forgot-password"
                mb={2}
                variant="button"
                fontSize="sm"
                position="absolute"
                top="2px"
                right={0}
              >
                Forgot password?
              </TextLink>
            </Box>

            {shouldShowSupportMessage && (
              <Text fontSize="sm" mt={4}>
                If you are having trouble, please email{" "}
                <TextLink isExternal href="mailto:support@privco.com">
                  support@privco.com
                </TextLink>{" "}
                and we will respond as quickly as possible.
              </Text>
            )}
          </Box>
          <Divider />
          <CreateAccountLink
            isAcademic={directAccessSettings.allowSubsidiaryUsers}
          />
        </SignInFormWrapper>
      )}
    </>
  );
};

export default SignInForm;