import type { ReactElement } from "react";
import Head from "next/head";

const AppHead = (): ReactElement => (
  <Head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/icons/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/icons/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="194x194"
      href="/icons/favicon-194x194.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="/icons/android-chrome-192x192.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/icons/favicon-16x16.png"
    />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#051931" />
    <meta name="apple-mobile-web-app-title" content="PrivCo" />
    <meta name="application-name" content="PrivCo" />
    <meta name="msapplication-TileColor" content="#051931" />
    <meta name="theme-color" content="#ffffff" />
  </Head>
);

export default AppHead;
