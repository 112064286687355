import type { ReactElement } from "react";
import { useState } from "react";
import { useRouter } from "next/router";
import { Button } from "@chakra-ui/react";
import { captureException } from "@sentry/nextjs";
import useUserStore from "stores/use-user-store";
import fetcher from "utils/fetcher";
import type { DirectAccessLoginResponse } from "types/api/auth/direct-access-login";

interface DirectAccessButtonProps {
  setShowSupportMessage: (shouldShowSupportMessage: boolean) => void;
}

const DirectAccessButton = ({
  setShowSupportMessage,
}: DirectAccessButtonProps): ReactElement => {
  const { updateUser } = useUserStore();
  const router = useRouter();

  const {
    query: { redirect },
  } = router;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getDirectAccessLogin = async () => {
    try {
      setIsLoading(true);

      const res = await fetcher<DirectAccessLoginResponse>(
        `/system/auth/direct-access-login`,
        {
          credentials: "include",
        }
      );

      if (
        res.destination &&
        res.destination !== "/" &&
        res.destination.startsWith("/")
      ) {
        await router.replace(`/signin?redirect=${res.destination}`, undefined);
      }

      updateUser({
        isLoggedIn: true,
      });

      router.push(`/${redirect ?? ""}`);
    } catch (error) {
      setShowSupportMessage(true);
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Button
      data-cy="submit-direct-access"
      colorScheme="emerald"
      w="full"
      isLoading={isLoading}
      onClick={getDirectAccessLogin}
    >
      Direct Access
    </Button>
  );
};

export default DirectAccessButton;
