import Cookies from "js-cookie";

interface Payload {
  iat: number;
  nbf: number;
  exp: number;
  data: {
    id: number;
    username: string;
    directAccess: boolean;
    intercom: string;
  };
}

const getPayloadCookie = (): Payload =>
  JSON.parse(Buffer.from(Cookies.get("payload"), "base64").toString());

export default getPayloadCookie;
