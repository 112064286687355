import type { ReactElement } from "react";
import { Icon } from "@chakra-ui/react";
import type { IconProps } from "@chakra-ui/react";

const PrivcoLogoColor = (props: IconProps): ReactElement => (
  <Icon viewBox="0 0 716 716" {...props}>
    <title>PrivCo Logo</title>
    <rect
      width="560.11"
      height="96.47"
      x="-30.52"
      y="116.45"
      fill="#51ff91"
      rx="46.78"
      transform="rotate(-30 249.534 164.682)"
    />
    <rect
      width="560.11"
      height="96.47"
      x="123.05"
      y="194.69"
      fill="#b0ffcf"
      rx="46.78"
      transform="rotate(-30 403.112 242.913)"
    />
    <rect
      width="560.11"
      height="96.47"
      x="37.97"
      y="410.7"
      fill="#82c9fd"
      rx="46.78"
      transform="rotate(-30 318.029 458.936)"
    />
    <rect
      width="560.11"
      height="96.47"
      x="186.29"
      y="491.97"
      fill="#1e90ff"
      rx="46.78"
      transform="rotate(-30 466.335 540.194)"
    />
  </Icon>
);

export default PrivcoLogoColor;
