import NextLink from "next/link";
import {
  Icon,
  Link,
  chakra,
  forwardRef,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import type { LinkProps } from "@chakra-ui/react";
import { RiExternalLinkLine } from "react-icons/ri";

interface TextLinkProps extends LinkProps {
  /**
   * If passed, the component will have an external link icon
   */
  hasIcon?: boolean;
  /**
   * The color to style the link with when inactive
   *
   * @defaultValue `"blue.600"`
   */
  color?: string;
  /**
   * The color to style the link with when it is active
   *
   * @defaultValue `"blue.800"`
   */
  activeColor?: string;
  /**
   * The side of the link to truncate the text on
   *
   * @defaultValue `"ltr"`
   */
  truncateDirection?: "ltr" | "rtl";
  /**
   * The way the link will be display
   *
   * @defaultValue `"inline"`
   */
  variant?: "inline" | "truncate" | "button";
  /**
   * Whether or not to prefetch the page for the NextLink, only applies when
   * `isExtenal` is set to `false`
   *
   * @defaultValue `true`
   */
  prefetch?: boolean;
}

/**
 * A standard anchor component default styles and optional variants
 */
const TextLink = forwardRef<TextLinkProps, "a">(
  (
    {
      children,
      variant = "inline",
      isExternal = false,
      hasIcon = false,
      fontSize = "inherit",
      fontWeight = "bold",
      color = "blue.600",
      activeColor = "blue.800",
      truncateDirection = "ltr",
      ...props
    },
    ref
  ) => {
    const linkButtonStyles = useMultiStyleConfig("Button", { variant: "link" });

    const linkStyles: Record<string, LinkProps> = {
      button: {
        display: "inline-flex",
        alignItems: "center",
      },
      truncate: {
        ...linkButtonStyles,
        borderRadius: 0,
        display: "inline-flex",
        alignItems: "center",
        maxW: "100%",
      },
    };

    const linkStyle = linkStyles[variant];

    return (
      <Link
        as={isExternal ? undefined : NextLink}
        ref={ref}
        {...linkStyle}
        fontSize={fontSize}
        isExternal={isExternal}
        fontWeight={fontWeight}
        color={color}
        _active={{
          color: activeColor,
        }}
        w={variant === "button" ? "auto" : undefined}
        {...props}
      >
        {variant === "truncate" ? (
          <chakra.div
            sx={{ direction: truncateDirection }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            width="min-content"
          >
            {children}
          </chakra.div>
        ) : (
          children
        )}
        {hasIcon && (
          <Icon
            as={RiExternalLinkLine}
            ml="2px"
            mb={variant === "inline" ? "3px" : 0}
          />
        )}
      </Link>
    );
  }
);

export default TextLink;
