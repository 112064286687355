import type { ReactElement, ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import AppHead from "components/app/app-head";

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout = ({ children }: DefaultLayoutProps): ReactElement => (
  <Box h="100%" overflowY="auto">
    <AppHead />
    {children}
  </Box>
);

export default DefaultLayout;
