import type { ReactElement } from "react";
import { Flex } from "@chakra-ui/react";
import { NextSeo } from "next-seo";
import SignInForm from "components/account/signin/signin-form";
import DefaultLayout from "components/app/layout/default-layout";

const SigninPage = () => (
  <>
    <NextSeo
      title="Sign In"
      canonical="https://system.privco.com/signin"
      openGraph={{
        title: "Sign In",
        url: "https://system.privco.com/signin",
      }}
    />

    <Flex
      as="main"
      direction="column"
      justify="flex-start"
      align="center"
      width="100%"
      minH="100%"
      bg="blue.900"
      px={4}
      py={[8, 12]}
      backgroundImage="url(/signin-background.svg);"
      backgroundRepeat="no-repeat"
      backgroundPosition="center right"
      backgroundAttachment="fixed"
    >
      <SignInForm />
    </Flex>
  </>
);

SigninPage.getLayout = (page: ReactElement) => (
  <DefaultLayout>{page}</DefaultLayout>
);

export default SigninPage;
