import { useSWRConfig } from "swr";
import type { ScopedMutator } from "swr/dist/types";

const companyProfileMatcher = /^\/system\/company\/.+/;

const investorProfileMatcher = /^\/system\/investor\/.+/;

/**
 * Clear all company and investor profile data from the `useSWR` cache
 */
type ClearProfileDataFunc = () => Promise<void>;

/**
 * Returns a function to clear the company and investor profile data in the `useSWR` cache.
 *
 * Used for when the auth state changes so the user does not see stale data.
 *
 * @returns The `clearProfileData` function
 */
const useClearProfileData = (): ClearProfileDataFunc => {
  const { cache, mutate } = useSWRConfig();

  const clearProfileData: ClearProfileDataFunc = async () => {
    if (!(cache instanceof Map)) {
      throw new Error(
        "matchMutate requires the cache provider to be a Map instance"
      );
    }

    const mutations: Promise<ScopedMutator>[] = [];

    cache.forEach((value, key) => {
      if (companyProfileMatcher.test(key) || investorProfileMatcher.test(key)) {
        mutations.push(mutate(key, undefined));
      }
    });

    await Promise.all(mutations);
  };

  return clearProfileData;
};

export default useClearProfileData;
