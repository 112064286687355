/**
 * Get an array from either an existing array of a given type or a single value
 * of that type
 *
 * @param input - Either an array of a type, or a single item of that type
 * @returns
 */
export const makeArray = <ArrType>(input: ArrType | ArrType[]): ArrType[] =>
  Array.isArray(input) ? input : [input];

/**
 * Get the first item from either an existing array of a given type or a single
 * value of that type
 *
 * @param input - Either an array of a type, or a single item of that type
 * @returns
 */
export const getFirst = <ArrType>(input: ArrType | ArrType[]): ArrType =>
  Array.isArray(input) ? input[0] : input;

/**
 * Get an array of a supplied length where each item is the index of the array
 *
 * @param length - The size of the array
 * @returns An array of array indices
 */
export const getIndexArray = (length: number): number[] => {
  const indexArray = [];

  for (let i = 0; i < length; i += 1) {
    indexArray.push(i);
  }

  return indexArray;
};
